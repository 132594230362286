import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from "reactstrap";
import YellowBorderButton from "../../common/Buttons/YellowBorderButton";
import './WatchListProfilingModal.css'
import { useDispatch, useSelector } from "react-redux";
import { getWatchListProfilingRequest, setWatchListProfilingSuccess } from "../../../redux/actions/watchListAction";
import WatchListProfilingTabMenu from "./WatchListProfilingTabMenu";
import MediaQuery from 'react-responsive';
import WatchListProfileCardComp from "./WatchListProfileCardComp";
import { EP_POWER_SEARCH_COMPANY, ET_WATCHLIST_PROFILIING_DOWNLOAD_REPORT_BTN_CLICK, ET_WATCHLIST_PROFILING_TAB_MENU_CLICK } from "../../../common/trackingConstants";
import { sendActivityTracking } from "../../../redux/actions/commonAction";

const watchListProfileTabMenuList = [
    {
        "menuText": "Company Status",
        "sectionId": "compStatus",
        "isSubSection": false,
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true, "formatType": "kmb"},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {   "menuText": "Age", 
        "sectionId": "compAgeTag",
        "isSubSection": false,
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true, "formatType": "kmb"},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Company Size",
        "sectionId": "compSizeTag",
        "isSubSection": false,
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true, "formatType": "kmb"},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Growth Rate",
        "sectionId": "compGrowthTag",
        "isSubSection": false,
        "tabs": {
            "Companies":{"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true, "formatType": "kmb"},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Health Rating",
        "sectionId": "PomandaRating",
        "isSubSection": false,
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true, "formatType": "kmb"},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Company Type",
        "sectionId": "CompanyTypeDesc",
        "isSubSection": false,
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true, "formatType": "kmb"},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Ownership",
        "sectionId": "Ownership",
        "isSubSection": false,
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true, "formatType": "kmb"},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Industry",
        "sectionId": "insustry",
        "isSubSection": true,
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true, "formatType": "kmb"},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Location",
        "sectionId": "location",
        "isSubSection": true,
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true, "formatType": "kmb"},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Next Accounts Date",
        "sectionId": "accountsLate",
        "isSubSection": false,
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true, "formatType": "kmb"},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Companies Top 10",
        "sectionId": "top10Companies",
        "isSubSection": false,
        "tabs": {
            "Companies": {"chart": false, "rawData": false},
            "Turnover": {"chart": true, "rawData": true, "formatType": "kmb"},
            "Employees": {"chart": true, "rawData": true}
        }
    }
]


const WatchListProfilingModal = (props) => {
    const dispatch = useDispatch()
    const [activeMenu, setActiveMenu] = useState({text: watchListProfileTabMenuList[0].menuText, sectionId: watchListProfileTabMenuList[0].sectionId, isSubSection: watchListProfileTabMenuList[0].isSubSection, tabs: watchListProfileTabMenuList[0].tabs})
    const watchlistProfiles = useSelector(state => state.watchListReducer.watchListProfilingObj)
    const [nodeData, setNodeData] = useState({id: "", name: "", child: []})
    const [aciveMenuData, setActiveMenuData] = useState([])
    const {
        toggleModal = () => { },
        watchListName="",
        watchListId="",
        button = {},
        watchListDescription=""
    } = props;
    const handleDownloadReport = () => {
        dispatch(sendActivityTracking({
            eventPage: EP_POWER_SEARCH_COMPANY,
            eventType: ET_WATCHLIST_PROFILIING_DOWNLOAD_REPORT_BTN_CLICK,
        }));
    }
    const {
        btnText="Download Report",
        btnOnClick= handleDownloadReport ,
        btncssClass='wlp-downlaod-btn'
    } = button

    const handleMenuClick = (item) => {
        setActiveMenu({ text: item.menuText, sectionId: item.sectionId, isSubSection: item.isSubSection, tabs: item.tabs })
        dispatch(sendActivityTracking({
            eventPage: EP_POWER_SEARCH_COMPANY,
            eventType: ET_WATCHLIST_PROFILING_TAB_MENU_CLICK,
            attribute1: activeMenu?.text
        }))
    }

    const handleChartNodeClick = (nodeData) => {
        if(nodeData?.child?.length){
            setNodeData({...nodeData, id: nodeData?.id, name: nodeData?.name, child: [...nodeData?.child]})
        }
    }

    useEffect(() => {
        dispatch(getWatchListProfilingRequest(watchListId))
        return (() => {
            dispatch(setWatchListProfilingSuccess(""))
        })
    },[])

    useEffect(() => {
        setActiveMenuData(watchlistProfiles?.[activeMenu.sectionId] )
        if(activeMenu?.isSubSection) {
            const activeMenuInitialChildren = watchlistProfiles?.[activeMenu.sectionId][0]?.children[0]
            const childData = activeMenuInitialChildren?.child
            setNodeData({id:"Companies", name: activeMenuInitialChildren?.name, child: childData?.length ? childData : []})
        } else {
            setNodeData({})
        }
    },[activeMenu])

    useEffect(() => {
        setActiveMenuData(watchlistProfiles?.[activeMenu?.sectionId])
    },[watchlistProfiles])

   
    return (
        <Modal isOpen={true} toggle={toggleModal} modalClassName="watchList-profiling-modal">
            <div className="wl-profiling-modal-header">
                <div className="wl-profiling-header-container">
                    <div className="wl-profiling-name-section">
                        <p className="wl-profiling-list-name">{`${watchListName} Profiling`}</p>
                        <p className="wl-profiling-list-desc" >{watchListDescription}</p>
                    </div>
                    <div className="wl-profiling-header-btn-section">
                        <MediaQuery minWidth={992}>
                            <ButtonWithToolTipComp id="watchListDownloadReportTooltip" btnProps={{btnText, btnOnClick, btncssClass}} />
                        </MediaQuery>
                        <i className="fa fa-close wlp-modal-close" onClick={toggleModal} />
                    </div>
                </div>
                <WatchListProfilingTabMenu
                    menuItemsList={watchListProfileTabMenuList}
                    activeMenu={activeMenu.text}
                    handleMenuClick={handleMenuClick}
                />
            </div>
            <ModalBody className="wl-profiling-modal-body">
                <div className="wl-profiling-cards-container">
                    <div className="wl-profiling-cards-section">
                        {aciveMenuData?.map((obj) => {
                            return (
                                <WatchListProfileCardComp 
                                graphData={obj} 
                                currentTabObj={activeMenu.tabs?.[obj?.["category"]]} 
                                watchListName={watchListName} 
                                topActiveMenuId={activeMenu.sectionId}
                                topActiveMenuText={activeMenu.text}
                                handleChartNodeClick={handleChartNodeClick}/>
                            )
                        })}
                    </div>
                    {
                        nodeData?.child?.length ?
                            (
                                <div className="wl-profiling-cards-section flex-column">
                                    <p className="wl-profiling-sub-card-heading">{nodeData?.name}</p>
                                    <div className="wl-profiling-cards-section">
                                        {nodeData.child.map((obj) => {
                                            return (
                                                <WatchListProfileCardComp
                                                    graphData={obj}
                                                    currentTabObj={activeMenu.tabs?.[obj["category"]]}
                                                    watchListName={watchListName}
                                                    topActiveMenuId={activeMenu.sectionId}
                                                    topActiveMenuText={activeMenu.text} />
                                            )
                                        })}
                                    </div>
                                </div>
                            ) : ""
                    }
                </div>
            </ModalBody>
            <MediaQuery maxWidth={991.98}>
                <ModalFooter className="wl-profiling-modal-footer">
                    <div className="wlp-download-btn-wrapper">
                        <ButtonWithToolTipComp id="watchListDownloadReportTooltip" btnProps={{btnText, btnOnClick, btncssClass}} />
                    </div>
                </ModalFooter>
            </MediaQuery>
        </Modal>
    )
}

const ButtonWithToolTipComp = (props) => {
    const {
        id="",
        btnProps={}} = props;
        const {
            btnText="",
            btnOnClick= () => { },
            btncssClass=""
        } = btnProps
    return(
        <>
            <YellowBorderButton text={btnText} handleClick={btnOnClick} cssClassName={btncssClass} id={id} />
            <UncontrolledTooltip placement="bottom" target={id} innerClassName="watchlist-download-report-tooltip">Coming Soon...</UncontrolledTooltip>
        </>
    )
}

export default WatchListProfilingModal